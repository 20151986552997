import { HStack } from "@chakra-ui/react";

export const Socials = () => {
    const baseAssetsUrl = process.env.PUBLIC_URL + '/assets';

    return (
        <HStack className="socials">
            <a href="https://www.linkedin.com/in/miguel-anciaes" target="_blank" rel="noreferrer">
                <img src={baseAssetsUrl + '/linkedin.png'} alt="linkedin" />
            </a>
            <a href="https://github.com/aanciaes" target="_blank" rel="noreferrer">
                <img src={baseAssetsUrl + '/github.png'} alt="github" />
            </a>
            <a href="https://twitter.com/mikeanciaes" target="_blank" rel="noreferrer">
                <img src={baseAssetsUrl + '/twitter.png'} alt="twitter" />
            </a>
        </HStack>
    );
};