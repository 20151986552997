import './App.css';
import { ChakraProvider } from "@chakra-ui/react"
import { UnderConstruction } from './components/construction/under_construction';

function App() {
  return (
    <ChakraProvider>
      <UnderConstruction/>
      
              {/* 
      <div className="App">

        <Java/>
        <Saying/>
        <Footer/>        
        
      </div>
      */}
    </ChakraProvider>
  );
}

export default App;
