import { Center, Flex, Text, VStack, Button } from "@chakra-ui/react"
import { ArrowDownIcon } from '@chakra-ui/icons'
import { Socials } from "../footer/socials"

export const UnderConstruction = () => {

    const showCV = () => {
        const baseUrl = process.env.PUBLIC_URL + '/assets';
        window.open(baseUrl + "/cv_anciaes.pdf", '_blank', 'noopener,noreferrer');
    }

    return (
        <Flex height={"100vh"} alignContent={"center"} justifyContent={"center"}>
            <Center>
                <VStack spacing={4}>
                    <Text fontSize={"3xl"}>Miguel Anciaes</Text>
                    <Text fontSize={"3xl"}>Under Construction</Text>
                    <Button onClick={showCV} rightIcon={<ArrowDownIcon />} colorScheme='blue' variant='outline'>
                        Download CV         
                    </Button>
                    <Socials />
                    <Text fontSize={"xs"}>08/12/2023 - Miguel Anciaes</Text>
                </VStack>
            </Center>
        </Flex>
    );
}